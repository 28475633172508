.button-container {
  position: relative;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  width: 180px;
  height: 180px;
}

.button-container a:before {
  content: '';
  display: inline-block;
  height: 48%;
}

.button-container a {
  transition: all 0.5s ease-out;
  background: rgba(88, 88, 88, 0.7);
  border-radius: 50%;
  vertical-align: middle;
  padding: 5%;
  color: #FFFFFF;
  position: absolute;
  font-size: 1.125em;
  font-weight: 700;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.button-container a:hover {
  background: none;
  border: none;
  opacity: 0;
}

.button-container img {
  width: 100%;
  height: auto;
  display: block
}
