:root {
  --baseblue: #006181;
  --primarycolor: #FFFFFF;
  --gray01: #F3F3F3;
  --bluegray: #CFD8DC;
  --lightgray: #C8C7CB;
  --gray02: #E5E5E8;
  --basegray: #444444;
  --padding: 10px;
}

.viewerContainer {
  border: 1px solid var(--baseblue);
  margin: auto;
  position: relative;
}

.xmlViewerXslt {
  padding: var(--padding);
  overflow: auto;
  height: calc(100% - (var(--padding) * 2));
}

.MuiAppBar-colorPrimary {
  background: var(--baseblue);
}
