:root {
  --padding: 10px;
}

.xmlViewerXslt {
  padding: var(--padding);
  overflow: hidden;
  height: calc(100vh - 420px);
}

.xmlViewerXslt iframe {
  resize: none;
  border: none;
  height: 100%;
  width: 100%;
}
