:root {
  --baseblue: #006181;
  --hovercolor: #437ca3;
  --primarycolor: #FFFFFF;
  --gray01: #F3F3F3;
  --bluegray: #CFD8DC;
  --lightgray: #C8C7CB;
  --gray02: #E5E5E8;
  --basegray: #444444;
  --black: #000000;
  --basebackground: #234256;
  --drawerwidth: 270px;
  --breadcrumb-theme-1: #006181;
  --breadcrumb-theme-2: #fff;
  --breadcrumb-theme-3: #234256;
  --breadcrumb-theme-4: #437ca3;
  /*
  --breadcrumb-theme-1: #e91e63;
  --breadcrumb-theme-2: #fff;
  --breadcrumb-theme-3: #d80d52;
  --breadcrumb-theme-4: #c70041;
  */
  background: #FFFFFF !important;
  flex-grow: 1;
}

a {
  color: var(--gray01) !important;
  text-decoration: underline !important;
}

.app-bar-shift {
  margin-right: var(--drawerwidth);
}

.container-center {
  text-align: center;
}

.container-error {
  padding-top: 10%;
  text-align: center;
  font-weight: bold;
  color: red;
}

.container-filter {
  margin-bottom: 30px !important;
}

.patient-buttons {
  padding-left: 18px;
  padding-bottom: 5px;
}

.patient-search-button {
  padding-left: 14px !important;
}

.patient-add-button {
  padding-left: 0 !important;
}

.container-header {
  position: fixed !important;
  height: 70px;
}

.container-history {
  /*margin: 40px 0 20px 35% !important;*/
  overflow-y: auto;
  padding-left: 15px;
  position: relative;
  top: 110px;
}

.container-login {
  max-width: 480px !important;
  padding: 120px;
  position: relative;
  top: 140px;
}

.container-logo {
  cursor: pointer;
  max-width: 85% !important;
}

.container-main {
  flex-grow: 1;
  margin-right: var(--drawerwidth) !important;
}

.container-navigation {
  background-color: var(--baseblue);
  /*height: 60px !important;*/
  height: 40px !important;
  /*max-width: 1280px;*/
  max-width: 100%;
  padding-left: 8% !important;
}

.container-patients {
  flex-grow: 1;
  margin: 30px 0 0 0;
  padding-right: 2% !important;
  position: relative;
  top: 110px;
  /*width: calc(100% - var(--drawerwidth)) !important;*/
  width: 90%;
}

.container-documents {
  flex-grow: 1;
  margin: 30px 0 0 0;
  position: relative;
  top: 81px;
  /*width: calc(100% - var(--drawerwidth)) !important;*/
  width: 90% !important;
}

.container-username {
  padding-bottom: 20px;
  padding-right: 0px !important;
  text-align: -webkit-right;
}

.documentIcon {
  color: var(--baseblue);
}

#document-types {
  /*width: 75%;*/
}

.drawer {
  flex-shrink: 0;
  width: var(--drawerwidth);
}

.drawer-header {
  display: flex;
  justify-content: flex-end;
}

footer {
  background-color: var(--basebackground) !important;
  bottom: 0;
  color: var(--primarycolor);
  height: 40px;
  position: fixed;
  text-align: center;
  width: 100%;
}

header {
  height: 100px;
}

.logo-img {
  width: 200px;
}

.icon-hide {
  display: none !important;
}

.filter-input-id {
  margin-left: 10px !important;
  max-width: 90px;
}

.filter-input-lastname {
  margin-left: -5px !important;
  max-width: 200px;
  min-width: 140px;
  width: 100%;
  /*width: 180px;*/
}

.filter-input-firstname {
  margin-left: 20px !important;
}

.label-login {
  font-size: 24px;
  font-weight: 500;
}

.navigation-bar {
  background-color: var(--baseblue);
  max-width: 100%;
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 999;
  /*
  height: 60px !important;
  padding: 10px;
  */
}

.navigation-patient-data {
  color: var(--gray01);
  font-size: 11px;
  padding-top: 3px;
  position: absolute;
  right: 9%;
  text-align: right;
}

.required-label {
  color: var(--baseblue);
  font-size: 11px;
  margin-left: 15px;
}

.required-fields {
  border: 1px solid var(--basebackground);
  border-radius: 4px;
  width: fit-content;
}

.timer-label {
  color: var(--baseblue);
  font-size: 11px;
  padding: 25px;
}

.timeout-message-button {
  font-size: 12px !important;
  height: 35px;
  width: 90px;
}

.timeout-message-icon {
  height: 20px;
  width: 20px;
}

.tooltip {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 6px 0;
  z-index: 20;
  /*border-bottom: 1px dotted black;*/
}
.tiptext {
  border: 1px solid var(--basegray);
  text-align: left;
  padding: 10px;
  width: max-content !important;
}
.tiptext-label {
  color: var(--baseblue);
}
.tooltip .tiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--gray01);
  color: var(--basegray);
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.tooltip .tiptext::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}
.tooltip:hover .tiptext {
  visibility: visible;
}
.tooltip.left .tiptext{
  top: -5px;
  right: 110%;
}
.tooltip.left .tiptext::after{
  margin-top: -5px;
  top: 50%;
  left: 100%;
  border-color: transparent transparent transparent var(--basegray);
}
.tooltip.right .tiptext{
  top: -44px;
  left: 110%;
}
.tooltip.right .tiptext::after{
    margin-top: -15px;
    top: 50%;
    right: 100%;
    border-color: transparent var(--basegray) transparent transparent;
}

.username-label {
  font-size: 18px;
  padding-right: 10px;
}

/*** Navigation Bar ***/
.breadcrumb {
  text-align: center;
  display: inline-block;
  /*box-shadow: 0 2px 5px rgba(0,0,0,0.25);*/
  overflow: hidden;
  border-radius: 5px;
  /*counter-reset: flag;*/
}

.breadcrumb a {
  align-items: center;
  display: flex;
}

.breadcrumb__step {
  cursor: pointer;
  text-decoration: none !important;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  padding: 0 15px 0 35px !important;
  position: relative;
  background: var(--breadcrumb-theme-2);
  color: var(--breadcrumb-theme-1);
  transition: background 0.5s;
}
.breadcrumb__step:first-child {
  padding-left: 20px !important;
  border-radius: 5px 0 0 5px;
}
.breadcrumb__step:first-child::before {
  left: 14px;
}
.breadcrumb__step:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 20px;
}
.breadcrumb__step:last-child::after {
  content: none;
}
/*
.breadcrumb__step::before {
  content: counter(flag);
  counter-increment: flag;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  position: absolute;
  top: 0;
  left: 30px;
  font-weight: bold;
  background: var(--breadcrumb-theme-2);
  box-shadow: 0 0 0 1px var(--breadcrumb-theme-1);
}
*/
.breadcrumb__step::after {
  content: '';
  position: absolute;
  top: 0;
  right: -18px;
  width: 36px;
  height: 36px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  border-radius: 0 5px 0 50px;
  background: var(--breadcrumb-theme-2);
  transition: background 0.5s;
  box-shadow: 2px -2px 0 2px var(--breadcrumb-theme-4);
}
.breadcrumb__step--active {
  color: var(--breadcrumb-theme-2);
  background: var(--breadcrumb-theme-1);
}
.breadcrumb__step--active::before {
  color: var(--breadcrumb-theme-1);
}
.breadcrumb__step--active::after {
  background: var(--breadcrumb-theme-1);
}
.breadcrumb__step:hover {
  color: var(--breadcrumb-theme-2);
  background: var(--breadcrumb-theme-3);
}
.breadcrumb__step:hover::before {
  color: var(--breadcrumb-theme-1);
}
.breadcrumb__step:hover::after {
  color: var(--breadcrumb-theme-1);
  background: var(--breadcrumb-theme-3);
}
.bold-label {
  font-weight: bold;
}
