.mg20 {
  margin: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.btn-choose {
  margin-right: 10px !important;
}

.btn-upload {
  position: absolute !important;
  right: 10px;
}
