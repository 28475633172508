:root {
  --padding: 10px;
  --searchBoxHeight: 60px;
}

.xmlViewerRaw {
  padding: var(--padding);
  overflow: auto;
  margin-top: 10px;
  height: calc(100vh - 474px);
  font-family: monospace;
  white-space: pre;
}

.textViewer {
  display: flex;
  flex-direction: column;
  padding: var(--padding);
  height: calc(100vh - 420px);
}

.textViewer textarea {
  font-family: monospace;
  resize: none;
  border: none;
  height: 100%;
}

.textViewer iframe {
  resize: none;
  border: none;
  height: 100%;
}

.highlight {
  color: red;
  font-weight: bold;
  background-color: yellow;
}

.foundOccurrences {
  padding: 2px 5px;
  color: green;
  font-weight: bold;
  background-color: yellow;
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}

.searchBox {
  width: calc(100% - var(--padding) * 2);
  padding: 3px;
  position: relative;
  top: var(--padding);
  left: var(--padding);
}

.searchBoxContainer {
  min-height: var(--searchBoxHeight);
}

.searchBoxGrow {
  padding-bottom: 14px;
}
